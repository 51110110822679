<template>
	<div>解决方案详情页</div>
</template>

<script>
export default{
	name:'SolutionDetail'
}
</script>

<style>
</style>
